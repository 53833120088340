import { Link } from 'gatsby'
import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import Header from '../components/header'
import SEO from '../components/seo'
import Shape from '../components/shape'
import Title from '../components/title'
import {
  Quote,
  SmallText,
  Title3,
  Title4,
  Title5,
  Title6
} from '../components/typography'
import Arrow from '../images/svg/arrow.svg'
import {
  fadeInQuicklyAnimation,
  popInQuicklyAnimation,
  resetPositionAnimation
} from '../styles/animations'
import Layout from './layout'
import { VisibilitySensor } from '../components/visibility-sensor'

const Main = styled.main`
  display: flex;
  flex-direction: column;
`

const TitleContainer = styled.div`
  flex: 1;
  padding: 2.5rem ${({ theme: { sideMarginDesktop } }) => sideMarginDesktop}
    3.75rem 11.9375rem;

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    padding: ${({ theme: { sideMarginMobile } }) => sideMarginMobile};
  }
`

const SprintSummaryOverflow = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  padding-bottom: 10rem;
`

const SprintSummaryContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: ${({ theme: { darkPurple } }) => darkPurple};
  padding: 3.75rem 25vw 0
    ${({ theme: { sideMarginDesktop } }) => sideMarginDesktop};
  margin-right: 7vw;
  border-bottom-right-radius: 56vw;

  @media screen and (max-width: ${({ theme: { tabletSize } }) => tabletSize}) {
    padding: 3rem 1.25rem 5rem 1.25rem;
    margin: 0;
    margin-right: -60vw;
    padding-right: 60vw;
    border-bottom-right-radius: 135vw;
  }

  transform: translateX(-100vw);
  ${resetPositionAnimation}
`

const SummaryStepContainer = styled.div`
  flex: 1 0 17rem;
  padding-left: 3.75rem;
  margin: 0 5vw 3.75rem 0;

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    flex: 1 1 auto;
  }

  ${fadeInQuicklyAnimation}

  :nth-of-type(1) {
    animation-delay: 0.8s;
  }
  :nth-of-type(2) {
    animation-delay: 1.2s;
  }
  :nth-of-type(3) {
    animation-delay: 1.6s;
  }
  :nth-of-type(4) {
    animation-delay: 2s;
  }
`

const SummaryTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`

const Circle = styled(Shape)`
  transform: translateY(-2px);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2.5rem;
  margin-right: 1.0625rem;
  margin-left: -3.75rem;
`

const BeginCircle = styled(Shape)`
  display: block;
  position: absolute;
  right: 1vw;
  bottom: 30%;
  width: 13.7rem;
  height: 13.7rem;
  border-radius: 13.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h5 {
    font-weight: 500;
  }
  > svg {
    margin-top: 0.625rem;
    width: 2rem;
    height: 1.5rem;
  }

  @media screen and (max-width: ${({ theme: { tabletSize } }) => tabletSize}) {
    left: 30vw;
    bottom: -7rem;
  }

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    width: 35vmin;
    height: 35vmin;
    bottom: -15vmin;
  }

  ${popInQuicklyAnimation}
  animation-delay: 2.5s;

  transition: transform 0.2s;
  :hover {
    transform: scale(1.1);
  }
`

const WhyContainer = styled.div<{ isVisible: boolean }>`
  flex: 1;
  padding: 0 12rem 6.25rem 12rem;

  @media screen and (max-width: ${({ theme: { tabletSize } }) => tabletSize}) {
    padding: ${({ theme: { sideMarginMobile } }) => sideMarginMobile};
    padding-bottom: 2.25rem;
  }

  > h3 {
    margin-bottom: 2.25rem;
  }
  > p {
    margin-bottom: 1.5rem;
  }
  > span {
    font-weight: 300;
    text-transform: uppercase;
  }

  opacity: ${({ isVisible }) => Number(isVisible)};
  transition: opacity 2s;
`

const WhatWeDo: FunctionComponent = () => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <Layout>
      <SEO title="What we do" />
      <Header />
      <Main>
        <TitleContainer>
          <Title>What we do</Title>
        </TitleContainer>

        <SprintSummaryOverflow>
          <SprintSummaryContainer>
            <SummaryStepContainer>
              <SummaryTitleContainer>
                <Circle color="pink">
                  <Title6>1</Title6>
                </Circle>
                <Title4>Discovery</Title4>
              </SummaryTitleContainer>
              <SmallText>
                The road to digital transformation starts with discovery, we use
                design sprints, a structured series of workshops that starts
                with a 'problem' and results in a user tested prototype within
                one week.
              </SmallText>
            </SummaryStepContainer>
            <SummaryStepContainer>
              <SummaryTitleContainer>
                <Circle color="pink">
                  <Title6>2</Title6>
                </Circle>
                <Title4>Strategy</Title4>
              </SummaryTitleContainer>
              <SmallText>
                We take a technology agnostic approach and strategise based on
                the user needs, current business infrastructure and scalebility
                to make sure the solution supports your short and long term
                plans.
              </SmallText>
            </SummaryStepContainer>
            <SummaryStepContainer>
              <SummaryTitleContainer>
                <Circle color="pink">
                  <Title6>3</Title6>
                </Circle>
                <Title4>Development</Title4>
              </SummaryTitleContainer>
              <SmallText>
                Agile development to european standards where you stay in the
                loop and in control via transparant, consistent progress
                reports.
              </SmallText>
            </SummaryStepContainer>
            <SummaryStepContainer>
              <SummaryTitleContainer>
                <Circle color="pink">
                  <Title6>4</Title6>
                </Circle>
                <Title4>Boosting</Title4>
              </SummaryTitleContainer>
              <SmallText>
                After launch support, not just maintaining your product, but
                boosting it using analytics, SEO and user feedback.
              </SmallText>
            </SummaryStepContainer>

            <Link to="/get-in-touch">
              <BeginCircle color="pink">
                <Title5>Start your </Title5>
                <Title5>Design Sprint</Title5>
                <Arrow />
              </BeginCircle>
            </Link>
          </SprintSummaryContainer>
        </SprintSummaryOverflow>

        <VisibilitySensor
          onChange={visible => visible && setIsVisible(visible)}
        >
          <WhyContainer isVisible={isVisible}>
            <Title3>Why we do it</Title3>
            <Quote>
              “The road to brilliant user experiences is built on amicable
              collaboration towards a common goal.”
            </Quote>
            <SmallText>- Tom Reed, founder & facilitator</SmallText>
          </WhyContainer>
        </VisibilitySensor>
      </Main>
    </Layout>
  )
}

export default WhatWeDo
